import React from 'react';
import SEO from "../components/seo"
import Layout from "../components/layout"

import image_1 from '../images/clients/clients_1.jpg'
import image_2 from '../images/clients/clients_2.jpg'
import image_3 from '../images/clients/exeltis.jpg'
import image_4 from '../images/clients/Omnilife.jpg'
import SliderBootstrap from '../components/SliderBootstrap';

export default function OurClients(){

    return(
        <Layout>
            <SEO 
                title="Our Clients"
                description="We help our clients seamlessly enter the Nigerian market by advising on the documentation required to get regulatory approvals"
                />
            <SliderBootstrap />
            <section id="our-clients">
                <div className="container">
                    <div>
                        <h3 className="section-title flex-center">Our Clients</h3>
                    </div>

                    <div class="row">
                    <div class="col s12 m8"> <img alt="savante consulting clients" className="responsive-img" src={image_1} /> </div>
                    <div class="col s12 m4"> <img alt="savante consulting clients" className="responsive-img" src={image_2}  /> </div>
                    <div class="col s12 m6"> <img alt="savante consulting clients" className="responsive-img" src={image_3}  /> </div>
                    <div class="col s12 m6"> <img alt="savante consulting clients" className="responsive-img" src={image_4}  /> </div>
                </div>
                </div>
            </section>

            <div class="container">
                
            </div>
        </Layout>
    );
}